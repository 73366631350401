<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="thetitle">IADGE分会会员</div>
    <div class="profile">
      <div class="title"><div class="infor">会员列表</div></div>
      <div class="amount">
        <div>会员有效会员数：</div>
        <div>{{ member_validity }}</div>
      </div>
      <div class="amount">
        <div>会员所有会员数：</div>
        <div>{{ member_all }}</div>
      </div>
      <div class="hintoperation">
        <div class="hint">
          <div>注：(电子邮件) 单击邮箱，您可以直接向该会员发送邮件。</div>
          <div>
            (取消会员)
            选中会员对应的“取消”框，然后选择“确定”框，您将取消此会员的资格。
          </div>
        </div>
        <div class="buttom">
          <!-- https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E5%8A%A0.svg -->
          <!-- https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%8B%E6%8B%89.svg -->
          <div class="add hoverbottom" @click="topath('addmember')">添加</div>
          <div>
            <a-popconfirm
              placement="bottom"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirmtwo"
            >
              <template #title>
                <div class="select">
                  <a-checkbox
                    :disabled="disabledlist[0]"
                    v-model:checked="select1"
                    @change="handupdate"
                  />
                  <div class="selecttype">查看分会所有会员</div>
                </div>
                <div class="select">
                  <a-checkbox
                    :disabled="disabledlist[1]"
                    v-model:checked="select2"
                    @change="handupdate1"
                  />
                  <div class="selecttype">查看分会任期已到期的会员</div>
                </div>
                <div class="select">
                  <a-checkbox
                    :disabled="disabledlist[2]"
                    v-model:checked="select3"
                    @change="handupdate2"
                  />
                  <div class="selecttype">查看已取消会员资格的会员</div>
                </div>
                <!-- <p>确认取消此会员的资格吗?</p> -->
                <!-- <p>{{ text }}</p> -->
              </template>
              <div class="screen hoverbottom">筛选</div>
            </a-popconfirm>
          </div>
        </div>
      </div>
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="change"
        >
          <template #name="{ text, record }">
            <div class="table-cell">
              <div>{{ text }}</div>
              <i
                class="icon-class hand hoverbottom"
                @click="handleIconClick(record)"
              ></i>
            </div>
          </template>
          <template #checkbox="{ record }">
            <div class="table-cell">
              <a-popconfirm
                placement="bottom"
                ok-text="确定"
                cancel-text="取消"
                @confirm="confirm(record.id)"
                @cancel="conery(record.index)"
                @visibleChange="coner(record.index)"
              >
                <!--  -->
                <!-- {{ record.index }} -->
                <template #title>
                  <p>确认取消此会员的资格吗?</p>
                  <!-- <p>{{ text }}</p> -->
                </template>
                <a-checkbox
                  v-model:checked="record.selected"
                  @change="handupdate(record.age)"
                />
              </a-popconfirm>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model:visible="visibler" centered="true" @ok="handleOk">
      <div>
        <div>请注意! 会员资格是不可转让的.</div>
        <div>您可以更正会员的姓名、邮箱和单位.</div>
      </div>
      <div class="profilety">
        <div class="row">
          <div class="titler">姓名：</div>
          <div class="input phone uped">
            <el-input v-model="lastname.name" placeholder=""></el-input>
          </div>
        </div>
        <div class="row">
          <div class="titler">邮箱：</div>
          <div class="input phone uped">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="row">
          <div class="titler">单位：</div>
          <div class="input phone uped">
            <el-input v-model="lastname.unit" placeholder=""></el-input>
          </div>
        </div>

        <div
          class="save hoverbottom backyellow"
          @click="savechanges()"
          :class="{ click: submit }"
        >
          保存更改
        </div>
      </div>
    </a-modal>
  </div>
</template>
        
    <style lang="scss">
</style>
    <script>
import { member, memberdetail, memberedit } from "../api/twice";
import { ElNotification } from "element-plus";
export default {
  name: "Home",
  data() {
    return {
      select1: false,
      member_all: "",
      member_validity: "",
      select2: false,
      disabledlist: [0, 0, 0],
      select3: false,
      visibler: false,
      confirmVisible: false,
      pagination: {
        current: 1, // 当前页码
        pageSize: 5, // 每页显示条目数
      },
      lastname: {
        name: "",
        email: "",
        unit: "",
      },
      columns: [
        {
          title: "会员编号",
          dataIndex: "member_number",
          key: "member_number",
          slots: { customRender: "member_number" },
        },
        {
          title: "名字",
          dataIndex: "member_name",
          key: "member_name",
          slots: { customRender: "name" },
          //   width: 80,
        },
        {
          title: "邮箱",
          dataIndex: "member_email",
          key: "member_email",
          ellipsis: true,
        },
        {
          title: "单位",
          dataIndex: "member_organization",
          key: "member_organization",
          ellipsis: true,
          width: 202,
        },
        {
          title: "会员类型",
          dataIndex: "member_position",
          key: "member_position",
          ellipsis: true,
        },
        {
          title: "添加日期",
          dataIndex: "zh_start_time",
          key: "zh_start_time",
          ellipsis: true,
        },
        {
          title: "到期日期",
          dataIndex: "zh_end_time",
          key: "zh_end_time",
          ellipsis: true,
        },
        {
          title: "取消资格",
          dataIndex: "name",
          key: "name",
          selected: false,
          slots: { customRender: "checkbox" },
        },
      ],
      data: [],
    };
  },
  components: {},
  mounted() {
    this.getmemberlist("all");
  },
  computed: {
    submit() {
      if (this.lastname.name && this.lastname.email && this.lastname.unit) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async savechanges() {
      const data = await memberedit(this.memberid, {
        member_name: this.lastname.name,
        member_email: this.lastname.email,
        member_organization: this.lastname.unit,
      });
      if (data) {
        ElNotification({
          title: "Success",
          message: "会员信息修改成功!",
          type: "success",
        });
        this.getmemberlist("all");
        this.visibler = false;
      }
      // console.log(data);
    },
    async getmemberlist(e) {
      const data = await member({
        perPage: 100,
        page: 1,
        condition: e,
      });
      this.member_all = data.member_all;
      this.member_validity = data.member_validity;
      // console.log(this.member_all,this.member_validity);
      this.data = data.data.data.map((a, v) => {
        // console.log(a, i, v);
        const zh_end_time =
          a.end_time.slice(0, 4) +
          "年" +
          a.end_time.slice(5, 7) +
          "月" +
          a.end_time.slice(8, 10) +
          "日";
        const zh_start_time =
          a.start_time.slice(0, 4) +
          "年" +
          a.start_time.slice(5, 7) +
          "月" +
          a.start_time.slice(8, 10) +
          "日";
        return {
          selected: false,
          zh_end_time,
          zh_start_time,
          ...a,
          index: v,
        };
      });
      console.log(this.data, 12345465);
    },
    handleConfirm() {

    },
    async confirm(e) {
      console.log(e);
      this.confirmVisible = true;
      const data = await memberedit(e, {
        disqualification_time: "cancel",
      });
      if (data) {
        ElNotification({
          title: "Success",
          message: "会员取消资格成功!",
          type: "success",
        });
        this.getmemberlist("all");
        // this.visibler = false;
      }
    },
    conery(e) {
      // console.log(e, 124);
      this.data[e].selected = false;
    },
    coner(e) {
      // console.log(e, 122335);
      this.data[e].selected = true;
      //   console.log(e);
    },

    confirmtwo() {
      console.log(122335, this.select1);
      if (this.select1) {
        this.getmemberlist("all");
      }
      if (this.select2) {
        this.getmemberlist("expire");
      }
      if (this.select3) {
        this.getmemberlist("disqualification");
      }
    },
    change(p, f, s) {
      this.pagination = p;
      console.log(p, f, s);
    },
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    conertwo() {
      //   this.data[e - 1].selected = true;
      //   console.log(e);
    },
    handleCancel() {
      //   this.confirmVisible = false;
    },

    async handleIconClick(record) {
      // console.log(record, 9999);
      this.memberid = record.id;
      const data = await memberdetail({
        id: record.id,
      });
      this.lastname.name = data.member_name;
      this.lastname.email = data.member_email;
      this.lastname.unit = data.member_organization;
      console.log(data);
      this.visibler = true;
      //   console.log(this.visible, 98765);
      // Handle icon click for the specific record
    },
    handupdate(e) {
      this.confirmVisible = true;
      if (e.target.checked) {
        this.disabledlist = this.disabledlist.map(() => true);
        this.disabledlist[0] = false;
      } else {
        this.disabledlist = this.disabledlist.map(() => false);
      }

      // console.log(1234566, e, this.confirmVisible, index);
    },
    handupdate1(e) {
      this.confirmVisible = true;
      if (e.target.checked) {
        this.disabledlist = this.disabledlist.map(() => true);
        this.disabledlist[1] = false;
      } else {
        this.disabledlist = this.disabledlist.map(() => false);
      }
    },
    handupdate2(e) {
      this.confirmVisible = true;
      if (e.target.checked) {
        this.disabledlist = this.disabledlist.map(() => true);
        this.disabledlist[2] = false;
      } else {
        this.disabledlist = this.disabledlist.map(() => false);
      }
    },
    handleOk(e) {
      console.log(e, 123456);
    },
  },
};
</script>
<style lang="scss">
.buttom {
  .ant-popover-inner {
    width: 209px !important;
  }
}
.select {
  display: flex;
  margin-bottom: 12px;
  .selecttype {
    margin-left: 10px;
  }
}
// .ant-checkbox-checked:after
.ant-checkbox {
  &:hover {
    border-color: #c10a74 !important;
  }
  &::after {
    border-color: #c10a74 !important;
  }
}
.table-cell {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #ca5040 !important;
    border-color: #ca5040 !important;
  }
}
.select {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #ca5040 !important;
    border-color: #ca5040 !important;
  }
}

.ant-popover-inner {
  padding: 24px 24px 24px 16px !important;
  .ant-popconfirm-message-icon {
    display: none !important;
  }
  .ant-popconfirm-buttons {
    display: flex;
    justify-content: space-between;
    .ant-btn-default {
      width: 68px;
      height: 32px;
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid #d15243;
      font-size: 12px;
      font-weight: 400;
      color: #d15243;
      line-height: 14px;
      &:hover {
        opacity: 0.6;
      }
    }
    .ant-btn-primary {
      width: 68px;
      height: 32px;
      background: #d15243;
      border-radius: 20px 20px 20px 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.uped {
  .el-input__wrapper {
    height: 38px;
    box-sizing: border-box;
    padding: 0 10px !important;
    border: 2px solid #777777 !important;
    border-radius: 8px !important;
  }
}

.ant-modal-footer {
  display: none;
}
.ant-modal-content {
  width: 378px;
}
.ant-table-cell {
  padding: 28px 15px !important;
  text-align: center;
}
.ant-table-thead {
  background: #f8e7e5 !important;

  .ant-table-cell {
    text-align: center !important;
  }

  th {
    background: #f8e7e5 !important;
  }
}
</style>
<style scoped lang="scss">
.profilety {
  margin-top: 28px;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .titler {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
    }
    .input {
      flex: 1;
    }
  }
  .save {
    width: 92px;
    height: 32px;
    text-align: center;
    background: #ccc;
    border-radius: 20px 20px 20px 20px;
    line-height: 32px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    margin-top: 28px;
    margin: 0 auto;
    cursor: no-drop;
  }
}
.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-class {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 25px;
    background: url(https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/icon_modify.svg);
  }
}

.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;
    // border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .hintoperation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .hint {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        :nth-child(2) {
          margin-left: 28px;
          margin-top: 4px;
        }
      }
      .buttom {
        display: flex;
        .add {
          width: 102px;
          height: 38px;
          border-radius: 40px 40px 40px 40px;
          border: 1px solid #c81677;
          font-size: 14px;
          font-weight: 400;
          color: #c81677;
          line-height: 38px;
          text-align: center;
          margin-right: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: "";
            display: inline-block;
            width: 18px; /* 设置图片的宽度 */
            height: 18px; /* 设置图片的高度 */
            background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E5%8A%A0.svg"); /* 替换为您的图片 URL */
            background-size: cover; /* 调整图片的显示方式 */
            // margin-left: 6px; /* 调整图片与 div 之间的间距 */
            margin-right: 6px;
            // transform: rotate(180deg);
          }
        }
        .screen {
          width: 102px;
          background: #c81677;
          height: 38px;
          border-radius: 40px 40px 40px 40px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            content: "";
            display: inline-block;
            width: 18px; /* 设置图片的宽度 */
            height: 18px; /* 设置图片的高度 */
            background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%8B%E6%8B%89.svg"); /* 替换为您的图片 URL */
            background-size: cover; /* 调整图片的显示方式 */
            margin-left: 6px; /* 调整图片与 div 之间的间距 */
            // margin-right: 6px;
            // transform: rotate(180deg);
          }
        }
      }
    }

    .amount {
      display: flex;
      margin-bottom: 20px;
      :nth-child(1) {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      :nth-child(2) {
        font-size: 18px;
        font-weight: bold;
        color: #c10974;
      }
    }
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #c81677;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: pointer;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        // .hint {
        //   font-size: 12px;
        //   font-weight: 400;
        //   color: #333333;
        //   line-height: 14px;
        //   margin-top: 4px;
        //   position: absolute;
        //   margin-bottom: 20px;
        //   margin-left: 20px;
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
        